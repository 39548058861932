:root {
  --dark-jungle-green: #071a1d;
  --prussian-blue: #0b2c3c;
  --raisin-black-1: #181c2b;
  --raisin-black-2: #282b39;
  --yellow-green: #76c520;
  --orange-soda: #ff5a3d;
  --cultured-1: #ededed;
  --cultured-2: #f2f6f7;
  --misty-rose: #f5dedb;
  --alice-blue: #f0f7ff;
  --seashell: #fff2f0;
  --cadet: #5d737e;
  --white: #fff;
  --black: #000;
  --opal: #8bb1b1;
  --ff-nunito-sans: "Nunito Sans", sans-serif;
  --ff-cairo: "Cairo", sans-serif;
  --fs-1: 1.875rem;
  --fs-2: 1.5rem;
  --fs-3: 1.375rem;
  --fs-4: 1.125rem;
  --fs-5: .875rem;
  --fs-6: .813rem;
  --fs-7: .75rem;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --transition: .25s ease;
  --section-padding: 100px;
  --shadow-1: 0 5px 20px 0 #182d541a;
  --shadow-2: 0 16px 32px #071a1d1a;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a, img, span, button, ion-icon {
  display: block;
}

button {
  font: inherit;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
}

address {
  font-style: normal;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-nunito-sans);
  scroll-behavior: smooth;
}

body {
  background: var(--white);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: var(--cadet);
  border-left: 2px solid var(--white);
}

.container {
  padding-inline: 15px;
}

button, a {
  transition: var(--transition);
}

.h1, .h2, .h3 {
  color: var(--dark-jungle-green);
  font-family: var(--ff-cairo);
  line-height: 1.3;
}

.h1 {
  font-size: var(--fs-1);
  line-height: 1;
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-4);
  font-weight: var(--font-weight, 700);
}

.h3 > a {
  color: inherit;
}

.btn {
  background: var(--orange-soda);
  color: var(--white);
  font-family: var(--ff-cairo);
  font-size: var(--fs-5);
  text-transform: var(--text-transform, capitalize);
  border: 1px solid var(--orange-soda);
  z-index: 1;
  padding: 10px 20px;
  position: relative;
}

.btn:is(:hover, :focus) {
  background: var(--black);
  color: var(--dark-jungle-green);
  border-color: var(--black);
}

.btn:before {
  content: "";
  background: var(--white);
  transition: var(--transition);
  z-index: -1;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn:is(:hover, :focus):before, .w-100 {
  width: 100%;
}

.section-subtitle {
  color: var(--orange-soda);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  margin-inline: auto;
  background: #ff5a3d1a;
  border-radius: 50px;
  width: max-content;
  margin-bottom: 15px;
  padding: 5px 20px;
}

.section-title {
  text-align: var(--text-align, center);
  margin-bottom: var(--margin-bottom, 50px);
}

.card-badge {
  background: var(--black);
  color: var(--white);
  font-size: var(--fs-7);
  text-transform: uppercase;
  padding: 4px 10px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.card-badge.green {
  background: var(--yellow-green);
}

.card-badge.orange {
  background: var(--orange-soda);
}

.has-scrollbar {
  scroll-snap-type: inline mandatory;
  padding-inline: 15px;
  align-items: flex-start;
  gap: 15px;
  margin-inline: -15px;
  padding-bottom: 60px;
  scroll-padding-left: 15px;
  display: flex;
  overflow-x: auto;
}

.has-scrollbar > li {
  scroll-snap-align: start;
  min-width: 100%;
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--cultured-2);
  outline: 2px solid var(--cadet);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: var(--cadet);
  border: 1px solid var(--cultured-2);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-button {
  width: 15%;
}

.header {
  z-index: 2;
  position: relative;
}

.header-top {
  background: var(--prussian-blue);
  padding-block: 15px;
}

.header-top .container, .header-top-list {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-top .container {
  gap: 8px 20px;
}

.header-top-list {
  gap: 15px;
}

.header-top-link {
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.header-top-link:is(:hover, :focus) {
  color: var(--orange-soda);
}

.header-top-link ion-icon {
  color: var(--orange-soda);
  --ionicon-stroke-width: 60px;
  font-size: 15px;
}

.header-top .wrapper, .header-top-social-list {
  align-items: center;
  display: flex;
}

.header-top .wrapper {
  gap: 20px;
}

.header-top-social-list {
  gap: 8px;
}

.header-top-social-link {
  color: var(--white);
  font-size: 15px;
}

.header-top-btn {
  background: var(--orange-soda);
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  padding: 4px 15px;
}

.header-top-btn:is(:hover, :focus) {
  --orange-soda: #ca3521;
}

.header-bottom {
  background: var(--white);
  padding-block: 25px;
}

.header-bottom .logo img {
  margin-inline: auto;
}

.navbar {
  background: var(--white);
  z-index: 5;
  visibility: hidden;
  width: 100%;
  max-width: 300px;
  height: 100%;
  padding: 60px 20px;
  transition: all .15s ease-in;
  position: fixed;
  top: 0;
  left: -310px;
  box-shadow: 0 3px 10px #0000004d;
}

.logo {
  color: #000;
  font-weight: bold;
  font-family: var(--ff-cairo);
}

.logo img {
  border-radius: 10rem;
  padding: 10px;
}

.navbar.active {
  visibility: visible;
  transition: all .25s ease-out;
  transform: translateX(310px);
}

.navbar-top {
  border-bottom: 1px solid var(--cultured-1);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 40px;
  display: flex;
}

.navbar-top .logo img {
  width: 200px;
}

.nav-close-btn ion-icon {
  --ionicon-stroke-width: 45px;
  padding: 5px;
  font-size: 20px;
}

.navbar-link {
  color: var(--cadet);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  padding-block: 15px;
}

.navbar-link:is(:hover, :focus) {
  color: var(--orange-soda);
}

.overlay {
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  z-index: 4;
  background: #000000b3;
  position: fixed;
  inset: 0;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.header-bottom-actions {
  background: var(--white);
  z-index: 3;
  justify-content: space-evenly;
  width: 100%;
  padding-block: 15px 10px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: -2px 0 30px #2e36dc33;
}

.header-bottom-actions-btn ion-icon {
  color: #1a1a1a;
  --ionicon-stroke-width: 40px;
  transition: var(--transition);
  margin-inline: auto;
  margin-bottom: 5px;
  font-size: 20px;
}

.header-bottom-actions-btn:is(:hover, :focus) ion-icon {
  color: var(--orange-soda);
}

.header-bottom-actions-btn span {
  color: var(--cadet);
  font-family: var(--ff-cairo);
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}

.hero {
  background: var(--cultured-2);
  padding-block: var(--section-padding);
}

.hero-content {
  margin-bottom: 60px;
}

.hero-subtitle {
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  display: flex;
}

.hero-subtitle ion-icon {
  color: var(--orange-soda);
}

.hero-subtitle span {
  color: var(--dark-jungle-green);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
}

.hero-title {
  margin-bottom: 20px;
}

.hero-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  border-left: 1px solid;
  margin-bottom: 30px;
  padding-left: 15px;
  line-height: 1.8;
}

.about {
  padding-block: var(--section-padding);
}

.about-banner {
  margin-bottom: 40px;
  position: relative;
}

.about-banner > img {
  width: 100%;
  max-width: max-content;
}

.about-banner .abs-img {
  border-radius: 4px;
  width: 50%;
  position: absolute;
  bottom: 100px;
  left: 15px;
}

.about .section-subtitle {
  margin-inline: 0;
}

.about .section-title {
  --text-align: right;
  --margin-bottom: 15px;
}

.about-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  text-align: justify;
  margin-bottom: 30px;
  line-height: 1.7;
}

.about-list {
  margin-bottom: 30px;
}

.about-item {
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  display: flex;
}

.about-item-icon {
  background: var(--misty-rose);
  border-radius: 50%;
  place-items: center;
  min-width: 45px;
  height: 45px;
  display: grid;
}

.about-item-icon ion-icon {
  color: var(--orange-soda);
  font-size: 18px;
}

.about-item-text {
  color: var(--cadet);
  font-size: var(--fs-5);
}

.about .callout {
  color: var(--cadet);
  font-size: var(--fs-5);
  font-weight: var(--fw-500);
  border-left: 4px solid var(--orange-soda);
  background: #e53f2a0d;
  margin-bottom: 40px;
  padding: 20px 25px;
  line-height: 1.8;
}

.about .btn {
  --text-transform: uppercase;
  max-width: max-content;
}

.service {
  background: var(--cultured-2);
  padding-block: var(--section-padding);
}

.service-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  display: flex;
}

.service-card {
  background: var(--white);
  text-align: center;
  box-shadow: var(--shadow-2);
  height: 100%;
  padding: 40px 30px;
  position: relative;
}

.service-card .card-icon {
  margin-inline: auto;
  width: max-content;
  margin-bottom: 20px;
}

.service-card .card-title {
  margin-bottom: 15px;
}

.service-card .card-title > a:is(:hover, :focus) {
  color: var(--orange-soda);
}

.service-card .card-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  margin-bottom: 25px;
  line-height: 1.8;
}

.service-card .card-link {
  color: var(--opal);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.service-card:is(:hover, :focus) .card-link {
  color: var(--orange-soda);
}

.service-card:after {
  content: "";
  background: var(--orange-soda);
  transition: var(--transition);
  width: 0;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.service-card:is(:hover, :focus):after {
  width: 100%;
}

.property {
  padding-block: var(--section-padding);
}

.property-card {
  border: 1px solid var(--alice-blue);
  box-shadow: var(--shadow-2);
}

.property-card .card-banner {
  aspect-ratio: 2 / 1.5;
  position: relative;
  overflow: hidden;
}

.property-card .card-banner a {
  height: 100%;
}

.property-card .card-banner img {
  object-fit: cover;
  height: 100%;
  transition: all .5s;
}

.property-card:hover .card-banner img {
  transform: scale(1.1);
}

.property-card .card-banner:before {
  content: "";
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(to top, #000000f2, #0000 30%);
  position: absolute;
  inset: 0;
}

.property-card .banner-actions {
  z-index: 1;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 15px 10px;
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
}

.banner-actions-btn {
  color: var(--white);
  font-size: var(--fs-6);
  align-items: flex-end;
  gap: 4px;
  line-height: 1;
  display: flex;
}

.banner-actions-btn ion-icon {
  font-size: 16px;
}

.banner-actions-btn:first-child {
  margin-right: auto;
}

.banner-actions-btn:is(:hover, :focus) {
  color: var(--orange-soda);
}

.property-card .card-content {
  border-bottom: 1px solid #0000001a;
  padding: 30px 15px 15px;
}

.card-price {
  color: var(--orange-soda);
  font-family: var(--ff-cairo);
  font-size: var(--fs-5);
  margin-bottom: 5px;
}

.card-price strong {
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
}

.property-card .card-title {
  --font-weight: var(--fw-600);
  margin-bottom: 15px;
}

.property-card .card-title > a:is(:hover, :focus) {
  color: var(--orange-soda);
}

.property-card .card-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  margin-bottom: 25px;
  line-height: 1.8;
}

.property-card .card-list {
  flex-wrap: wrap;
  align-items: center;
  row-gap: 15px;
  display: flex;
}

.property-card .card-item {
  color: var(--cadet);
  font-size: var(--fs-5);
  padding-block: 5px;
}

.property-card .card-item:not(:last-child) {
  border-right: 1px solid #0003;
  margin-right: 20px;
  padding-right: 15px;
}

.property-card .card-item :is(strong, ion-icon) {
  display: inline-block;
}

.property-card .card-item ion-icon {
  margin-bottom: -2px;
  margin-left: 2px;
}

.property-card .card-item span {
  margin-top: 5px;
}

.card-footer {
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px;
  display: flex;
}

.card-author {
  align-items: center;
  gap: 10px;
  display: flex;
}

.author-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.author-name > a {
  color: var(--dark-jungle-green);
  font-family: var(--ff-cairo);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  margin-bottom: 3px;
}

.author-name > a:is(:hover, :focus) {
  color: var(--orange-soda);
}

.author-title {
  color: var(--cadet);
  font-size: var(--fs-7);
}

.card-footer-actions {
  align-items: center;
  gap: 10px;
  display: flex;
}

.card-footer-actions-btn {
  background: var(--cultured-2);
  color: var(--cadet);
  place-items: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  display: grid;
}

.card-footer-actions-btn:is(:hover, :focus) {
  background: var(--orange-soda);
  color: var(--white);
}

.features {
  background: var(--cultured-2);
  padding-block: var(--section-padding);
}

.features-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 20px;
  display: flex;
}

.features-list > li {
  width: calc(50% - 10px);
}

.features-card {
  background: var(--white);
  box-shadow: var(--shadow-1);
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.features-card:is(:hover, :focus) {
  background: var(--orange-soda);
}

.features-card .card-icon {
  background: var(--seashell);
  color: var(--orange-soda);
  border-radius: 50%;
  place-items: center;
  width: 60px;
  height: 60px;
  font-size: 28px;
  display: grid;
}

.features-card .card-icon ion-icon {
  --ionicon-stroke-width: 20px;
}

.features-card .card-title {
  color: var(--dark-jungle-green);
  font-family: var(--ff-cairo);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  text-align: center;
  transition: var(--transition);
}

.features-card:is(:hover, :focus) .card-title {
  color: var(--white);
}

.features-card .card-btn {
  background: var(--white);
  color: var(--cadet);
  transition: var(--transition);
  border-radius: 50%;
  place-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 0 0 10px #182d541a;
}

.features-card:is(:hover, :focus) .card-btn {
  color: var(--orange-soda);
}

.blog {
  padding-block: var(--section-padding);
}

.blog-card {
  box-shadow: var(--shadow-2);
}

.blog-card .card-banner {
  aspect-ratio: 2 / 1.5;
  overflow: hidden;
}

.blog-card .card-banner img {
  object-fit: cover;
  height: 100%;
  transition: all .5s;
}

.blog-card:is(:hover, :focus) .card-banner img {
  transform: scale(1.1);
}

.blog-content {
  padding: 30px;
}

.blog-card .card-meta-list {
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  display: flex;
}

.blog-card :is(.card-meta-link, .publish-date) {
  color: var(--cadet);
  font-size: var(--fs-7);
  font-weight: var(--fw-600);
  transition: var(--transition);
  align-items: center;
  gap: 5px;
  display: flex;
}

.blog-card :is(.card-meta-link, .publish-date) ion-icon, .blog-card .card-meta-link:is(:hover, :focus) {
  color: var(--orange-soda);
}

.blog-title {
  --font-weight: var(--fw-600);
  font-size: 1rem;
}

.blog-card .blog-title:is(:hover, :focus) {
  color: var(--orange-soda);
}

.blog-content-bottom {
  border-top: 1px solid #0000001a;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
}

.blog-card .read-more-btn {
  color: var(--orange-soda);
  font-size: var(--fs-7);
  font-weight: var(--fw-600);
  text-transform: uppercase;
}

.cta {
  background: linear-gradient(to bottom, var(--white) 50%, var(--raisin-black-1) 50%);
}

.cta-card {
  background: var(--orange-soda);
  box-shadow: var(--shadow-2);
  padding: 50px 25px;
}

.cta-card .card-content {
  margin-inline: auto;
  max-width: max-content;
  margin-bottom: 30px;
}

.cta-card .card-title {
  color: var(--white);
  margin-bottom: 15px;
  line-height: 1.3;
}

.cta-card .card-text {
  color: var(--white);
  font-size: var(--fs-5);
  line-height: 1.8;
}

.cta-btn {
  color: var(--black);
  background: var(--white);
  box-shadow: var(--shadow-2);
  border-color: var(--white);
  align-items: center;
  gap: 10px;
  margin-inline: auto;
  display: flex;
}

.cta-btn:is(:hover, :focus) {
  color: var(--white);
  background: none;
  border-color: #0000;
}

.cta-btn:before {
  background: var(--black);
}

.footer {
  background: var(--raisin-black-1);
  color: var(--white);
  margin-bottom: 68px;
}

.footer .container {
  padding-inline: 30px;
}

.footer a {
  color: inherit;
}

.footer-top {
  padding-top: 80px;
  padding-bottom: 40px;
}

.footer-brand {
  margin-bottom: 70px;
}

.footer-brand .logo {
  margin-bottom: 15px;
}

.section-text {
  font-size: var(--fs-5);
  max-width: 45ch;
  margin-bottom: 30px;
  line-height: 1.8;
}

.contact-list {
  gap: 15px;
  margin-bottom: 30px;
  display: grid;
}

.contact-link {
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.contact-link ion-icon {
  font-size: 18px;
}

.contact-link :is(address, span) {
  font-size: var(--fs-5);
  transition: var(--transition);
}

.contact-link:is(:hover, :focus) span {
  color: var(--orange-soda);
}

.social-list {
  align-items: center;
  gap: 20px;
  display: flex;
}

.footer-list:not(:last-child) {
  margin-bottom: 50px;
}

.footer-list-title {
  font-family: var(--ff-cairo);
  font-size: var(--fs-3);
  font-weight: var(--fw-700);
  margin-bottom: 15px;
}

.footer-link {
  font-size: var(--fs-5);
  padding-block: 10px;
}

.footer-link:is(:hover, :focus) {
  color: var(--orange-soda);
}

.footer-bottom {
  background: var(--raisin-black-2);
  padding-block: 25px;
}

.copyright {
  font-size: var(--fs-5);
  text-align: center;
}

.copyright a {
  display: inline-block;
}

.copyright a:is(:hover, :focus) {
  color: var(--orange-soda);
}

@media (width >= 600px) {
  :root {
    --fs-2: 1.875rem;
  }

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

  .has-scrollbar {
    gap: 25px;
    margin-inline: -25px;
    padding-inline: 25px;
    scroll-padding-left: 25px;
  }

  .has-scrollbar > li {
    min-width: calc(50% - 12.5px);
  }

  .service-list > li {
    width: calc(50% - 15px);
  }

  .property .container {
    max-width: unset;
    padding-inline: 25px;
  }

  .cta-card {
    --fs-2: 1.5rem;
  }

  .footer-link-box {
    justify-content: space-between;
    gap: 20px;
    display: flex;
  }
}

@media (width >= 768px) {
  :root {
    --fs-1: 2.5rem;
    --fs-5: .938rem;
    --fs-6: .875rem;
  }

  .container {
    max-width: 720px;
  }

  .btn {
    --fs-5: 1rem;
    padding: 12px 28px;
  }

  .header-top {
    padding-block: 5px;
  }

  .header-top .wrapper {
    margin-left: auto;
  }

  .header-top-social-list {
    gap: 12px;
  }

  .header-top-social-link {
    font-size: 1rem;
  }

  .header-top-btn {
    padding: 10px 20px;
  }

  .header-bottom-actions {
    all: unset;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .header-bottom .container {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .header-bottom-actions-btn ion-icon {
    margin-bottom: 0;
  }

  .header-bottom-actions-btn span {
    display: none;
  }

  .header-bottom-actions-btn {
    background: var(--white);
    box-shadow: var(--shadow-2);
    width: 50px;
    height: 50px;
  }

  .hero-content {
    max-width: 400px;
  }

  .about .section-title {
    max-width: 30ch;
  }

  .about-text {
    max-width: 55ch;
  }

  .about-list {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .features-list > li {
    width: calc(33.33% - 13.33px);
  }

  .features-card {
    gap: 20px;
  }

  .features-card .card-icon {
    width: 80px;
    height: 80px;
    font-size: 32px;
  }

  .features-card .card-title {
    --fs-6: 1.125rem;
  }

  .blog-card {
    --fs-7: .875rem;
  }

  .blog-title {
    font-size: 1.25rem;
  }

  .blog .card-meta-list {
    gap: 30px;
  }

  .cta-card {
    --fs-2: 1.625rem;
  }

  .footer {
    margin-bottom: 0;
  }
}

@media (width >= 992px) {
  :root {
    --fs-1: 3.125rem;
    --fs-4: 1.375rem;
  }

  .container {
    max-width: 970px;
  }

  .btn {
    padding: 15px 40px;
  }

  .header-top-list, .header-top .wrapper {
    gap: 30px;
  }

  .hero-content {
    max-width: unset;
    margin-bottom: 0;
  }

  .hero .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    display: grid;
  }

  .about .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 60px;
    display: grid;
  }

  .about-banner {
    margin-bottom: 0;
  }

  .about-banner > img {
    width: 100%;
  }

  .service-list > li {
    width: calc(33.33% - 20px);
  }

  .property-card .card-content {
    padding-inline: 30px;
  }

  .card-footer {
    padding: 20px 30px 30px;
  }

  .features-list {
    column-gap: 30px;
  }

  .features-list > li {
    width: calc(25% - 30px);
  }

  .blog-title {
    font-size: 1.375rem;
  }

  .cta-card {
    --fs-2: 1.875rem;
    --fs-5: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    display: flex;
  }

  .cta-card :is(.card-content, .cta-btn) {
    margin: 0;
  }

  .footer-top .container {
    justify-content: space-between;
    display: flex;
  }

  .footer-brand {
    max-width: 300px;
    margin-bottom: 0;
  }

  .footer-link-box {
    flex-basis: 550px;
  }
}

@media (width >= 1200px) {
  :root {
    --fs-2: 2.75rem;
    --fs-4: 1.5rem;
  }

  .container {
    max-width: 1200px;
  }

  .has-scrollbar > li {
    min-width: calc(33.33% - 16.66px);
  }

  .header-bottom {
    padding-block: 30px;
  }

  .header-bottom-actions-btn:last-child, .navbar-top, .overlay {
    display: none;
  }

  .navbar, .navbar.active {
    all: unset;
  }

  .navbar-list {
    align-items: center;
    gap: 30px;
    display: flex;
  }

  .navbar-link {
    color: var(--dark-jungle-green);
    --fs-5: 1.125rem;
    text-transform: capitalize;
  }

  .header {
    padding-bottom: 114px;
  }

  .header-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .header.active .header-bottom {
    padding-block: 20px;
    animation: .25s ease-out forwards slideDown;
    position: fixed;
    top: -94px;
    bottom: auto;
    box-shadow: 0 10px 50px #2e36dc33;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(100%);
    }
  }

  .hero-text {
    max-width: 450px;
    margin-bottom: 40px;
    padding-left: 30px;
  }

  .features-card .card-icon {
    width: 100px;
    height: 100px;
    font-size: 45px;
  }

  .features-card .card-title {
    --fs-6: 1.375rem;
  }

  .cta-card {
    --fs-2: 2.25rem;
    padding-inline: 60px;
  }

  .footer {
    --fs-5: 1rem;
  }

  .footer-link-box {
    flex-basis: 700px;
  }
}

#footer-ig-link {
  align-items: center;
  gap: 1rem;
  display: flex;
}
/*# sourceMappingURL=index.5bbeb60e.css.map */
